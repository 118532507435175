<template>
  <div class="yj-conten-div">
    <a-layout style="height:100%;">
      <a-layout-content class="yj-conten-div_cont">
        <div class="yj-conten" style="min-height:calc(100% - 48px)">
          <a-layout style="height:100%;">
            <a-layout-header style="background: #f0f2f5;margin-bottom:14px;padding:0;height:auto">
              <a-row :gutter="16">
                <a-col class="gutter-row" :span="10">
                  <Task-detail :OrderID.sync="OrderID"></Task-detail>
                </a-col>
                <a-col class="gutter-row" :span="7">
                  <Complete-detail :OrderID.sync="OrderID"></Complete-detail>
                </a-col>
                <a-col class="gutter-row" :span="7">
                  <Partner-detail v-bind:OrderID.sync="OrderID"></Partner-detail>
                </a-col>
              </a-row>
            </a-layout-header>
            <a-layout-content style="height:100%;">
               <Money-detail v-bind:OrderID.sync="OrderID"></Money-detail>
            </a-layout-content>
          </a-layout>
        </div>
      </a-layout-content>
    </a-layout>
  </div>
</template>
 <script>
import TaskDetail from "./components/TaskDetail";
import CompleteDetail from "./components/CompleteDetail";
import PartnerDetail from "./components/PartnerDetail";
import MoneyDetail from "./components/MoneyDetail";
export default {
  mame: "Detail_Reward",
  data() {
    return {
      OrderID:""
    };
  },
  components: {
    TaskDetail,
    CompleteDetail,
    PartnerDetail,
    MoneyDetail
  },
  methods: {
       //方法
    async getParams() {
      this.OrderID= this.$route.query.ID; 
    }
  },
  mounted() {
  },
  created:function(){
    this.getParams();
  }
};
</script>
<style scoped>
.ant-card-body p {
  margin-bottom: 15px;
}
</style>
  