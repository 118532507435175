<template>
    <div class="gutter-box" style="box-shadow:none;height:320px">
        <a-card title="任务详情" :bordered="false">
            <p>任务名称：{{TaskDetail.TaskName}}</p>
            <p>任务类型：{{TaskDetail.TaskTypeName}}</p>
            <p>任务佣金：{{TaskDetail.TaskBonus}}</p>
            <p>开始时间：{{TaskDetail.BeginTime}}</p>
            <p>结束时间：{{TaskDetail.EndTime}}</p>
        </a-card>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "TaskDetail",
        data() {
            return {
                TaskDetail: [],
                t_OrderID: this.OrderID,
            };
        },
        props: {
            //组件属性
            OrderID:{
              type:String,
              default:""
            } 
        },
        methods: {
            //方法
            getTaskDetail() {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/GetTaskInfo",
                    data: {
                        OrderID: self.t_OrderID
                    },
                    OnSuccess: function (res) {

                        if (res.data != []) {
                            self.TaskDetail = res.data;
                            res.data.Data.forEach(element => {
                            element.TaskBonus = parseFloat(element.TaskBonus).toFixed(2);
                            element.BeginTime = util.TimeStamptoDateTime(element.BeginTime, 'yyyy-MM-dd hh:mm:ss');
                            element.EndTime = util.TimeStamptoDateTime(element.EndTime, 'yyyy-MM-dd hh:mm:ss');
                            });
                        } else {
                            self.message.eror("任务不存在！");
                        }
                    }
                };
                http.Post(op);
            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {

        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            this.getTaskDetail();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>