<template>
  <div class="gutter-box" style="box-shadow:none;height:320px;">
    <a-card title="合伙人信息" :bordered="false">
    <a slot="extra" @click="PartnerDetailInfo">详情</a>
      <div class="yj-orderds">
        <div class="yj-orderds-l">
          <img :src="PartnerDetail.PartnerImg" height="94" />
        </div>
        <div class="yj-orderds-c">
          <div>姓名:{{PartnerDetail.PartnerName}}</div>
          <div>手机:{{PartnerDetail.PartnerPhone}}</div>
          <div>昵称:{{PartnerDetail.PartnerNickName}}</div>
        </div>
      </div>
    </a-card>
  </div>
</template>
<script>
import http from "@/Plugin/Http.js";

export default {
  name: "PartnerDetail",
  data() {
    return {
        PartnerDetail:[],
        p_OrderID:this.OrderID,
        p_PartnerID:""
    };
  },
  props: {
    // //组件属性
    OrderID:{
      type:String,
      default:""
    } 
  },
   methods: {
   PartnerDetailInfo() {
      var self = this;
      self.$router.push({
      name: "OperationsDetail",
      query: {
           ID: self.p_PartnerID
        }
      })
    },
    GetPartnerInfo() {
      var self = this;
      var op = {
        url: "/ShopModule/Order/GetPartnerInfo",
        data: {
          OrderID: this.p_OrderID
        }, 
        OnSuccess: function(res) {
          console.log(res);
          if (res.data != []) {
            res.data.PartnerPhone = res.data.PartnerPhone.length != 11 ? "" : res.data.PartnerPhone;
            self.p_PartnerID = res.data.PartnerID;
            self.PartnerDetail = res.data;
          } 
        }
      };
      http.Post(op);
    }
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    this.GetPartnerInfo();
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>