<template>
  <div class="gutter-box" style="box-shadow:none;height:320px;">
    <a-card title="任务完成信息" :bordered="false">
          <div>发展合伙人数量:{{ComplateDetail.DevelpCount}}</div>
          <div>阅读数量:{{ComplateDetail.ReadCount}}</div>
          <div>拉新数量:{{ComplateDetail.NewUserCount}}</div>
          <div>成交订单量:{{ComplateDetail.OrderCount}}</div>
          <div>意向用户数量:{{ComplateDetail.WantUserCount}}</div>
    </a-card>
  </div>
</template>
<script>
import http from "@/Plugin/Http.js";

export default {
  name: "ComplateDetail",
  data() {
    return {
        ComplateDetail:[],
        c_OrderID:this.OrderID

    };
  },
  props: {
    // //组件属性
    OrderID:{
      type:String,
      default:""
    } 
  },
   methods: {
    GetComplateInfo() {
      var self = this;
      var op = {
        url: "/ShopModule/Order/GetComplateInfo",
        data: {
          OrderID: this.c_OrderID
        }, 
        OnSuccess: function(res) {
          console.log(res);
          self.ComplateDetail = res.data;
        }
      };
      http.Post(op);
    }
  },
  computed: {
    //计算属性
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    this.GetComplateInfo();
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>